body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
}

.row{
  --bs-gutter-x: 0 !important;
}

.header{
  background-color: rgb(38,53,36);
  color: white;
}

.headerLogo{
  /* width: 15vw; */
  height: 15vh;
}

.adminCard{
  border: 2px black solid;
  border-radius: 10px;
  height: 200px !important;
  width: 200px !important;
  padding-top: .5rem;
  /* display: flex;
  justify-content: center; */
}

.adminCol{
  display: flex;
  justify-content: center;
}

.adminImg{
  height: 125px;
  width: 125px !important;
}

.appImg{
  width: 100px;
  border-radius: 15px;
}

.appImgCol{
  border-right: black solid 2px;
  
}

.appDiv{
  background-color: hsla(0, 0%, 50%, 0.25);
}

.downloadImg{
  width: 150px !important;
  /* margin-top: .25rem; */
  margin-bottom: .25rem;
}

.appInfoDiv{
  max-width: 1000px !important;
}

.w-5{
  max-width: 100px !important;
}